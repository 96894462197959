<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row class="match-height" v-if="!isLoading">
        <template v-if="Object.keys(mhd_orders).length > 0">
        <template v-for="(data, index) in mhd_orders.data">
          <transition
          :name="$store.state.appConfig.layout.routerTransition"
          mode="out-in" :key="index"
          >
          <b-col md="12">
            <b-card no-body>
              <b-row>
                <!-- <b-col md="3">
                <img :src="data.thumbnail" width="150" />
                </b-col> -->
                <b-col md="9">
                  <b-card-body>
                    <b-card-title>#{{data.id}}</b-card-title>
                    <b-row class="m-0">
                      {{data.total}} {{$t('SAR')}} -
                      {{data.created_at | formatDate}}
                      <b-badge class="d-block ml-1" :variant="data.status == 'completed' ? 'light-success' : 'light-warning'">
                        {{data.status == 'completed' ? $t('Completed') : $t('Pending')}}
                      </b-badge>
                    </b-row>
                  </b-card-body>
                </b-col>
                <b-col md="3" align-self="center" :class="`text-${$store.getters['app/currentBreakPoint'] == 'xs' ? 'left' : 'center'}`">
                  <b-button
                    variant="gradient-primary"
                    class="btn-icon"
                    :to="`/mhd-orders/${data.id}`"
                  >
                    <feather-icon icon="EditIcon" /> {{$t('Show')}}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          </transition>
        </template>
        </template>
        <template v-else>
          <b-col>
            <b-card class="text-center">
                <span>{{$t('content not found')}}.</span>
            </b-card>
          </b-col>
        </template>
      </b-row>
      <pagination :limit="7" :data="mhd_orders" @pagination-change-page="getMhdOrders"></pagination>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'

export default {
  name: 'MhdOrdersIndex',
  data() {
    return {
      mhd_orders: {},
      isLoading: false,
    }
  },
  mounted() {
    this.getMhdOrders();
  },
  methods: {
    getMhdOrders(page = 1){
      this.isLoading = true
      useJwt.get('/mhd-orders/get-all',{params: {
          page: page,
      }})
      .then((response) => {
        // console.log(response.data)
        this.mhd_orders = response.data.data
        this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    }
  }
}
</script>
